import $router from '@weepower/core/scripts/wee-routes';
import './bootstrap';

const common = [
    () => import('../components/navigation-bar').then((m) => m.default || m),
];

$router.map([
    {
        path: '/',
        handler: [
            ...common,
            () => import('../components/homepage-hero').then((m) => m.default || m),
            () => import('../components/homepage-content/headshots').then((m) => m.default || m),
            () => import('../components/get-in-touch').then((m) => m.default || m),
            () => import('../components/homepage-content/filmography').then((m) => m.default || m),
        ],
    },
    {
        path: '/acting-clips',
        handler: [
            ...common,
        ],
    },
]).run();
